<template>
	<div class="record">
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" name="nickname" label="用户名称">
						<a-input v-model:value="formState.nickname" placeholder="请输入用户名称" />
					</a-form-item>
					<a-form-item class="ui-form__item" name="phone" label="用户手机">
						<a-input v-model:value="formState.phone" placeholder="请输入用户手机" />
					</a-form-item>
					<a-form-item label="资产类型" name="type" class="ui-form__item">
						<a-select v-model:value="formState.type" style="width: 180px;" allow-clear placeholder="请选择资产类型">
							<a-select-option value="POINT">积分</a-select-option>
							<a-select-option value="COUPON">优惠券</a-select-option>
							<a-select-option value="MEMBER">特色会员</a-select-option>
							<a-select-option value="UNION_CARD">一卡通</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item label="操作类型" name="operateType" class="ui-form__item">
						<a-select v-model:value="formState.operateType" style="width: 180px;" allow-clear placeholder="请选择操作类型">
							<a-select-option value="GRANT_MEMBER">发放特色会员</a-select-option>
							<a-select-option value="DISABLED_MEMBER">禁用/启用特色会员</a-select-option>
							<a-select-option value="RENEW_MEMBER">续费特色会员</a-select-option>
							<a-select-option value="GRANT_POINT">发放积分</a-select-option>
							<a-select-option value="SUB_POINT">扣减积分</a-select-option>
							<a-select-option value="GRANT_UNION_CARD">发放一卡通</a-select-option>
							<a-select-option value="ADD_UNION_CARD_BALANCE">增加一卡通余额</a-select-option>
							<a-select-option value="SUB_UNION_CARD_BALANCE">扣减一卡通余额</a-select-option>
							<a-select-option value="GRANT_COUPON">发放优惠券</a-select-option>
							<a-select-option value="REMOVE_COUPON">移除优惠券</a-select-option>
							<a-select-option value="DISABLED_COUPON">禁用/启用优惠券</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item class="ui-form__item" name="adminUser" label="操作人">
						<a-input v-model:value="formState.adminUser" placeholder="请输入操作人" />
					</a-form-item>
					
					<a-form-item class="ui-form__item" label="操作时间">
						<a-range-picker v-model:value="time"></a-range-picker>
					</a-form-item>
				</a-row>
				<a-row>
					<a-col :span="18">
						<exportReport type="userAssetsRecordExportStrategy" :searchData="searchData"></exportReport>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<cTable ref="cTable" :isPage='true' :isRequest="true" :searchData="searchData" :requestFun="getUserAssetsRecord" :columns="columns"
				rowKey="id" size="middle">
				<template #bodyCell="{ column, record }">
					<template v-if="column.key === 'type'">
						<div>{{ transType(record.type) }}</div>
					</template>
					
					<template v-if="column.key === 'operateType'">
						{{ transOperateType(record.operateType) }}
					</template>
					
					<template v-if="column.key === 'createTime'">
						{{ transDateTime(record.createTime) }}
					</template>
				</template>
			</cTable>
		</a-spin>
	</div>
</template>

<script>
	import {
		Icon
	} from "@/components/icon/icon.js";
	import exportReport from '@/components/exportReport/exportReport.vue';
	import cTable from '@/components/cTable/index.vue';
	import { getUserAssetsRecord } from '@/service/modules/member.js';
	export default {
		name: "record",
		components: {
			Icon,
			exportReport,
			cTable
		},
		data() {
			return {
				loading: false,
				showAll: false,
				formState: {

				},
				searchData: {},
				time: [],
				columns: [{
						title: "用户名称",
						dataIndex: "nickname",
					},
					{
						title: "用户手机",
						dataIndex: "phone",
						width: 100
					},
					{
						title: "资产类型",
						key: "type",
					}, {
						title: '操作类型',
						key: 'operateType'
					},
					{
						title: "内容",
						dataIndex: "content",
						width: 150
					},
					{
						title: "操作人",
						dataIndex: "adminUser",
						width: 100
					},
					{
						title: "操作时间",
						key: "createTime",
						width: 150
					}
				],
			};
		},
		mounted() {
			this.onSearch();
		},
		methods: {
			getUserAssetsRecord: getUserAssetsRecord,
			onSearch() {
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				if (this.time && this.time.length) {
					this.searchData.startTime = this.time[0].startOf('days').unix();
					this.searchData.endTime = this.time[1].endOf('days').unix();
				}
				this.getData();
			},
			reset() {
				this.time = [];
				this.$refs.form.resetFields();
				this.onSearch();
			},
			getData() {
				this.$refs.cTable.toQuery();
			},
			transType(type) {
				if (type === 'POINT') {
					return '积分';
				} else if (type === 'COUPON') {
					return '优惠券';
				} else if (type === 'MEMBER') {
					return '特色会员';
				} else if (type === 'UNION_CARD') {
					return '一卡通';
				} else {
					return '-';
				}
			},
			transOperateType(type) {
				if (type === 'GRANT_MEMBER') {
					return '发放特色会员';
				} else if (type === 'DISABLED_MEMBER') {
					return '禁用/启用特色会员';
				} else if (type === 'RENEW_MEMBER') {
					return '续费特色会员';
				} else if (type === 'GRANT_POINT') {
					return '发放积分';
				} else if (type === 'SUB_POINT') {
					return '扣减积分';
				} else if (type === 'GRANT_UNION_CARD') {
					return '发放一卡通';
				} else if (type === 'ADD_UNION_CARD_BALANCE') {
					return '增加一卡通余额';
				} else if (type === 'SUB_UNION_CARD_BALANCE') {
					return '扣减一卡通余额';
				} else if (type === 'GRANT_COUPON') {
					return '发放优惠券';
				} else if (type === 'REMOVE_COUPON') {
					return '移除优惠券';
				} else if (type === 'DISABLED_COUPON') {
					return '禁用/启用优惠券';
				}
			}
		}
	};
</script>

<style scoped>
	.ui-form__item {
		margin-right: 20px;
	}
</style>